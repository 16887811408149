<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <span>
        <h3 class="ml-2">Crear nueva membresía</h3>
      </span>
      <b-button size="sm" variant="warning" @click="goToHome"> <b-icon-arrow-left></b-icon-arrow-left> Regresar</b-button>
    </div>
    <b-overlay :show="isSavingMembership" rounded class="new-membership-card">
      <ValidationObserver ref="createMembership" v-slot="{ invalid }">
        <b-form @submit.prevent="save">
          <b-tabs>
            <b-tab title="General">
              <b-row class="mt-1 border rounded p-1">

                <b-col md="">
                  <ValidationProvider rules="required" name="codigo">
                    <b-form-group label="Código" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        v-model="code"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="">
                  <ValidationProvider rules="required" name="nombre">
                    <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        v-model="name"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="">
                  <ValidationProvider rules="required" name="Tipo de pago">
                    <b-form-group label="Tipo de pago" slot-scope="{ valid, errors }">
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="paymentType"
                      >
                        <option :value="null">Tipo de pago</option>
                        <option v-for="pt in paymentTypes" :key="pt.id" :value="pt.id">
                          {{ pt.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="">
                  <ValidationProvider rules="required" name="Tipo de retorno">
                    <b-form-group
                      label="Tipo de retorno"
                      slot-scope="{ valid, errors }"
                    >
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="retorno"
                      >
                        <option :value="null">Tipo de retorno</option>
                        <option v-for="ret in retornos" :key="ret.id" :value="ret.id">
                          {{ ret.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="12" class="mb-2" />


                <b-col md="1" class="mr-1">
                  <div class="mb-1">
                    <b-form-checkbox
                      v-model="status"
                      :value="true"
                      switch
                      class="custom-control-success"
                    > Activo
                    </b-form-checkbox>
                  </div>
                </b-col>

                <b-col md="2" class="mr-1">
                  <div class="mb-1">
                      <b-form-checkbox
                        v-model="canSubscribe"
                        :value="true"
                        switch
                        class="custom-control-success"
                      > Puede acceder a suscripciónes
                      </b-form-checkbox>
                  </div>
                </b-col>


                <b-col md="12" class="mb-2" />

                <b-col md="6">
                  <ValidationProvider  name="blackouts">
                    <b-form-group label="Blackouts" slot-scope="{ valid, errors }">
                      <v-select
                        v-model="membershipBlackout"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        label="name"
                        :options="blackouts"
                        :state="errors[0] ? false : valid ? true : null"
                      />
                      <div class="text-danger" style="font-size: 0.857rem">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="6">
                  <ValidationProvider  name="Notas">
                    <b-form-group label="Notas de membresías">
                      <b-form-textarea
                        v-model="note"
                        placeholder="Escribe la nota"
                        rows="4"
                      >
                      </b-form-textarea>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Beneficios y Cutoff">
              <b-row class="mt-1 mb-1 border rounded p-1">
                <b-col md="6">
                  <b-row align-v="center">
                    <b-col md="4" ><h4 class="m-0">Beneficios</h4></b-col>
                    <b-col><b-input v-model="filter" size="sm" placeholder="Filtros para la tabla.."></b-input></b-col>
                  </b-row>
                  <hr />
                  <b-table
                    responsive
                    :filter="filter"
                    :items="benefitOptions"
                    :fields="fields"
                    class="mb-0"
                    sticky-header="400px"
                    v-if="benefitOptions.length"
                  >
                    <!-- Optional default data cell scoped slot -->

                    <template #cell(actions)="row">
                      <b-form-group class="custom-input-margin">
                        <b-form-input
                          class="form-control"
                          type="number"
                          @keypress="onlyNumber"
                          v-model="row.item.qty"
                        >
                        </b-form-input>
                      </b-form-group>
                    </template>

                    <template #cell(discount)="row">
                      <b-form-group class="custom-input-margin">
                        <b-form-input
                          class="form-control"
                          type="number"
                          @keypress="onlyNumber"
                          v-model="row.item.discount"
                        >
                        </b-form-input>
                      </b-form-group>
                    </template>
                    <template #cell(initCan)="row">
                      <b-form-group class="custom-input-margin">
                        <b-form-input
                          class="form-control"
                          type="number"
                          @keypress="onlyNumber"
                          v-model="row.item.initCan"
                        >
                        </b-form-input>
                      </b-form-group>
                    </template>

                    <template #cell(endCan)="row">
                      <b-form-group class="custom-input-margin">
                        <b-form-input
                          class="form-control"
                          type="number"
                          @keypress="onlyNumber"
                          v-model="row.item.endCan"
                        >
                        </b-form-input>
                      </b-form-group>
                    </template>
                    <template #cell(status)="row">
                      <div class="">
                        <b-form-checkbox
                          v-model="row.item.state"
                          :value="true"
                          switch
                          inline
                          unchecked-value="false"
                          @change="optionSelectingBenefit(row.item, $event)"
                          class="custom-control-success"
                        >
                        </b-form-checkbox>
                      </div>
                    </template>
                  </b-table>
                </b-col>

                <b-col md="6" class="border-left">
                  <h4 class="mb-2">Cutoff</h4><hr/>
                  <b-table
                    responsive
                    :filter="filterCutoff"
                    :items="cutoff"
                    :fields="fieldsCutoff"
                    class="mb-0"
                    sticky-header="400px"
                    v-if="cutoff.length"
                  >
                    <!-- Optional default data cell scoped slot -->
                    <template #cell(actions)="row">
                      <b-form-group class="custom-input-margin">
                        <b-form-input
                          class="form-control"
                          type="number"
                          @keypress="onlyNumber"
                          v-model="row.item.days"
                        >
                        </b-form-input>
                      </b-form-group>
                    </template>

                    <template #cell(status)="row">
                      <div class="">
                        <b-form-checkbox
                          v-model="row.item.state"
                          :value="true"
                          switch
                          inline
                          unchecked-value="false"
                          @change="optionSelectingCutoff(row.item, $event)"
                          class="custom-control-success"
                        >
                        </b-form-checkbox>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Políticas de Cancelación">
              <b-row class="mt-1 mb-1 border rounded p-1">
                <b-col md="12" class="mb-1"> <!-- Políticas de cancelación -->
                  <h4>Agregar políticas de cancelación a la membresía</h4>

                  <ValidationObserver
                    v-slot="{ invalid }"
                    tag="form"
                    :disabled="true"
                    ref="membershipNewPoliciesForm"
                  >
                    <b-form @submit.prevent="addPolicy" class="mb-1">
                      <b-row align-v="end">
                        <b-col md="">
                          <ValidationProvider name="Nombre" rules="required|max:100">
                            <b-form-group slot-scope="{ errors }" class="m-0">
                              <label class="mt-2">Nombre*</label>
                              <b-form-input
                                placeholder="Nombre política"
                                v-model="newPolicy.name"
                                @keypress="onlyNumbersLetters"
                                class="form-control"
                                :state="errors.length > 0 ? false : null"
                              />
                              <b-form-invalid-feedback style="position: absolute;">{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col md="">
                          <ValidationProvider name="Fecha Inicio" rules="">
                            <b-form-group slot-scope="{ errors }" class="m-0">
                              <label>Fecha Inicio</label>
                              <flat-pickr
                                :config="setDateInConfigGlobal"
                                :class="`form-control flatpickr-color ${errors.length > 0 ? 'is-invalid' : ''}`"

                                v-model="newPolicy.dateInit"
                                placeholder="Seleccione una fecha"
                                :state="errors.length > 0 ? false : null"
                              />
                                <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                              <!-- <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback> -->
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col md="">
                          <ValidationProvider name="Fecha Fin" rules="">
                            <b-form-group slot-scope="{ errors }" class="m-0">
                              <label>Fecha Fin</label>
                              <flat-pickr
                                :config="setDateOutConfigGlobal"
                                :class="`form-control flatpickr-color ${errors.length > 0 ? 'is-invalid' : ''}`"

                                v-model="newPolicy.dateEnd"
                                placeholder="Seleccione una fecha"
                                :state="errors.length > 0 ? false : null"
                              />
                                <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                              <!-- <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback> -->
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col>
                          <ValidationProvider name="Días antes de penalizar" rules="required|max_value:366" >
                            <b-form-group slot-scope="{  errors }" class="m-0">
                              <label class="mt-2">Días antes de penalizar* </label>
                              <b-form-input
                                min="0"
                                max="366"
                                class="form-control"
                                type="number"
                                placeholder="Indique días"
                                @keypress="onlyNumber"
                                v-model="newPolicy.days"
                                :state="errors.length > 0 ? false : null"
                              />
                              <b-form-invalid-feedback style="position: absolute;">{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col md="1" class="ml-3">
                          <b-button
                            type="submit"
                            class="float-right"
                            variant="primary"
                            :disabled="invalid"
                          > <feather-icon icon="PlusIcon" size="12" />
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </ValidationObserver>
                </b-col>

                <b-col md="12" v-if="policies.length" class="mt-2"> <!-- Políticas de Cancelación -->
                  <h4>Políticas de cancelación en la membresía</h4>

                  <div v-if="policies.length">
                    <table class="table table-responsive">
                      <thead class="sticky-top">
                        <tr>
                          <th class="custom-table-title-padding col-md-1 text-nowrap">
                            <label> Prioridad </label>
                          </th>
                          <th class="custom-table-title-padding col-md-2">
                            <label> Nombre </label>
                          </th>
                          <th class="custom-table-title-padding col-md-2">
                            <label> Fecha inicio </label>
                          </th>
                          <th class="custom-table-title-padding col-md-2">
                            <label> Fecha fin </label>
                          </th>
                          <th class="custom-table-title-padding col-md-1">
                            <label> Días para cancelar </label>
                          </th>

                          <th class="custom-table-title-padding col-md-1 text-center text-nowrap">
                            <label> Status </label>
                          </th>
                          <th class="custom-table-title-padding col-md-1 text-center text-nowrap">
                            <label> Borrar </label>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(policy, index) in policies"
                          :key="policy.key"
                          class="custom-table-padding-tr"
                        >
                          <td>
                            <ValidationProvider
                              :name="'Prioridad política '+(index+1)"
                              :rules="`required|min_value:1|max_value:${policies.length}`"
                              v-slot="v"
                              :id="'Prioridad política '+(index+1)"
                            >
                              <b-form-group class="wth-4 m-0">
                                <b-form-input
                                  size="sm"
                                  step="1"
                                  min="0"
                                  class="form-control"
                                  type="number"
                                  v-model="policy.priority"
                                  :state="v.errors[0] ? false : null"
                                />
                              </b-form-group>

                              <b-popover v-if="!!v.errors[0]" :target="'Prioridad política '+(index+1)" triggers="hover" placement="auto">
                                <!-- <template #title>{{ policy.name }}</template> -->
                                <label class="text-danger">{{ v.errors[0] }}</label>
                              </b-popover>
                            </ValidationProvider>
                          </td>
                          <td scope="row" class="">
                            <div class="ml-1">{{ policy.name }}</div>
                          </td>
                          <td scope="row">
                            <ValidationProvider :name="'Fecha Inicio politica '+ (index+1)" rules="">
                              <b-form-group class="custom-input-margin" slot-scope="{ errors }">
                                <flat-pickr
                                  :config="dateInConfig"
                                  :class="`form-control flatpickr-color ${errors.length > 0 ? 'is-invalid' : ''}`"
                                  v-model="policy.dateInit"
                                  placeholder="Seleccione una fecha"
                                  :state="errors.length > 0 ? false : null"
                                />
                                  <!-- @input="verifyDatePolicy('dateInit', policy, policy.dateInit)" -->
                                  <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                                <!-- <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback> -->
                              </b-form-group>
                            </ValidationProvider>
                          </td>
                          <td scope="row">
                            <ValidationProvider :name="'Fecha Fin politica '+ (index+1)" rules="">
                              <b-form-group class="custom-input-margin" slot-scope="{ errors }">
                                <flat-pickr
                                  :config="dateInConfig"
                                  :class="`form-control flatpickr-color ${errors.length > 0 ? 'is-invalid' : ''}`"
                                  v-model="policy.dateEnd"
                                  placeholder="Seleccione una fecha"
                                  :state="errors.length > 0 ? false : null"
                                />
                                  <!-- @input="verifyDatePolicy('dateEnd', policy, policy.dateEnd)" -->
                                  <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                                <!-- <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback> -->
                              </b-form-group>
                            </ValidationProvider>
                          </td>
                          <td scope="row">
                            <ValidationProvider :name="'días política '+(index+1)" rules="required" >
                              <b-form-group class="custom-input-margin" slot-scope="{ errors }">
                                <b-form-input
                                  step="1"
                                  min="0"
                                  max="100.00"
                                  class="form-control"
                                  type="number"
                                  v-model="policy.days"
                                  :state="errors.length > 0 ? false : null"
                                />
                                <!-- <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback> -->
                              </b-form-group>
                            </ValidationProvider>
                          </td>
                          <td class="text-center">
                            <b-form-checkbox
                              v-model="policy.status"
                              v-b-tooltip.hover
                              title="Estado"
                              switch
                              inline
                              :unchecked-value="false"
                              class="custom-control-success m-0"
                            />
                          </td>
                          <td class="text-center">
                            <b-button
                              size="sm"
                              v-b-tooltip.hover
                              title="Borrar"
                              @click="deletePolicy(policy.key)"
                              variant="danger"
                            > <feather-icon icon="Trash2Icon" size="12" />
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <b-row class="mt-2">
            <b-col>
              <div class="float-right">
                <b-button
                  type="submit"
                  class="btn-block"
                  variant="primary"
                  :disabled="invalid || isSavingMembership"
                >
                  <b-spinner small v-if="isSavingMembership" /> Guardar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { showAlertMessage, stringAleatorio } from '@/helpers/helpers'
import { benefitsCuttoffBlackouts, createArraysToSaveAMembership } from '@/helpers/fivesClubHelper'
import { optionsSelectCutoff } from '@/data/fivesClub'
import { utils } from '@/modules/fivesClub/mixins/utils'
import * as moment from 'moment'
import flatPickr from "vue-flatpickr-component";

import vSelect from 'vue-select';
export default {
  mixins: [utils],
  components: {
    vSelect,
    flatPickr
  },
  async created(){
    await this.init()
  },
  data() {
    return {
      filter: "",
      filterCutoff: "",
      fields: [
        { key: "status", label: "Añadir", class: "text-center" },
        { key: "name", label: "Nombre" },
        { key: "actions", label: "Cantidad" },
        { key: "discount", label: "Descuento" }
      ],
      fieldsCutoff: [
        { key: "status", label: 'Estado', class: "text-center" },
        { key: "label", label: "Nombre" },
        { key: "actions", label: "Días" , class: "text-center" },
      ],
      fieldsPolicies: [
        { key:'name', label: 'Nombre' },
        { key:'dateInit', label: 'Fecha inicio', sortable: true },
        { key:'dateEnd', label: 'Fecha fin', sortable: true },
        { key:'days', label: 'Días antes de penalizar' },
        // { key:'status', label: 'Activo', class: 'text-center' },
        { key:'action', label: 'Acciones' },
      ],
      cutoff: structuredClone(optionsSelectCutoff),
      policies: [],
      newPolicy: {
        key: stringAleatorio(),
        name: null,
        dateInit: null,
        dateEnd: null,
        days: null,
        status: true,
        priority: null,
      },
      dateInConfig: {
        minDate: moment().format("YYYY-MM-DD")
      },
      name: "",
      code:'',
      note:"",
      typeMembership: null,
      paymentType: null,
      retorno: null,
      optionsBenefits: [],
      optionsCutoff: [],
      membershipBenefits: [],
      membershipBlackout: [],
      membershipCutoff: [],
      showReport: false,
      excludeFeeCalculation: false,
      status: true,
      canSubscribe: false,
      //loader
      isSavingMembership: false,
    };
  },
  computed: {
    ...mapState('fivesClubCatalogs', [ 'paymentTypes', 'retornos', 'blackouts', 'benefitOptions', 'cutoffs']),
    ...mapState('auth', ['user']),
  
    disabledCurrentDates(){
      const dates = this.policies.map(policy => {
        return {
          from: policy.dateInit,
          to: policy.dateEnd
        }
      })
      return dates
    },
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['saveMembership', 'fetchMemberships', 'fetchBenefitInfo', 'getInitialContentForMembershipsEdition']),
    ...mapMutations('fivesClubCatalogs', ['setMemberships', 'setBenefitTitles','setSelectedMembership']),
    async init(){
      await this.getInitialContentForMembershipsEdition({
        paymentTypes: !this.paymentTypes?.length > 0,
        retornos: !this.retornos?.length > 0,
        blackouts: !this.blackouts?.length > 0,
        benefits: !this.benefitOptions?.length > 0,
        // benefits: !this.benefits?.length > 0,
      })
    },
    setDateInConfigGlobal() {
      return {
        minDate: moment().format("YYYY-MM-DD"),
        disable: this.disabledCurrentDates
      }
    },
    setDateOutConfigGlobal(){
      return {
        minDate: this.newPolicy.dateInit,
        disable: this.disabledCurrentDates
      }
    },
    async save() {
      const validPolicies = this.checkPriority()
      if (!validPolicies) return false

      const [blackouts, benefits, cuttoffs] = createArraysToSaveAMembership(
        this.membershipBlackout,
        this.membershipBenefits,
        this.membershipCutoff
      )
      this.isSavingMembership = true
      const payload = {
        idUser: this.user.idUser,
        name: this.name,
        code: this.code,
        idType: null, //tipo de membresía
        paymentType: this.paymentType,
        returnType: this.retorno,
        showReport: false,
        excludeCalculo: false,
        status: Boolean(this.status),
        canSubscribe: this.canSubscribe,
        //arreglos
        benefits,
        note:this.note,
        blackout: blackouts,
        cutoff: cuttoffs,
        policies: this.policies
      }
      const { status, message, data } = await this.saveMembership(payload)

      if (status) {
        showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
        Object.assign(this.$data, this.$options.data());
        this.$refs.createMembership.reset() //reseteo form

        //vuelvo a pedir la info del back
        // TODO data.idMembership,
        const memberships = await this.fetchMemberships()
        const benefits = await this.fetchBenefitInfo()
        this.setMemberships(memberships)
        this.setBenefitTitles(benefits)
        this.cutoff = optionsSelectCutoff
        // const membership = memberships.find(m => m.id === data.idMembership ) //busco en el state membresia para editar
        this.isSavingMembership = false
        if(data?.idMembership) this.$router.push({ name: 'edit-membership', params: { idMembership: data.idMembership } })
      }
      this.isSavingMembership = false
    },
    setMembershipToEdit(membership) {
      const data = structuredClone(membership) // rompo la fucking referencia v.2
      const membresia = benefitsCuttoffBlackouts(data)
      membresia.newBenefits = []
      membresia.newCutoffs = []
      this.setSelectedMembership(membresia)
      this.$router.push({ name: 'edit-membership', params: { idMembership: membresia.id } })
    },
    optionSelectingBenefit(option, e) {
      const bool = e == true ? true : false;
      if (bool == true) {
        this.membershipBenefits.push(option);
      } else {
        this.membershipBenefits = this.membershipBenefits.filter(
          (b) => b.id !== option.id
        );
      }
    },
    removeOptionCutoff(option) {
      const data = { ...option };
      this.membershipCutoff = this.membershipCutoff.filter(
        (b) => b.id !== data.id
      );
    },
    optionSelectingCutoff(option, e) {
      const bool = e == true ? true : false;
      if (bool == true) {
        option.status = bool;
        this.membershipCutoff.push(option);
      } else {
        this.membershipCutoff = this.membershipCutoff.filter(
          (b) => b.id !== option.id
        );
      }
    },
    // Políticas de Cancelación
    async addPolicy(){
      const isValid = await this.$refs.membershipNewPoliciesForm.validate()
      if (isValid) {
        // const validDateInit = this.verifyDatePolicy('dateInit', this.newPolicy, this.newPolicy.dateInit)
        // const validDateEnd = this.verifyDatePolicy('dateEnd', this.newPolicy, this.newPolicy.dateEnd)

        // if (validDateInit && validDateEnd) {
        // }
        this.newPolicy.priority = this.policies.length + 1

        this.policies.push(this.newPolicy)
        this.newPolicy = {
          key: stringAleatorio(),
          name: null,
          dateInit: null,
          dateEnd: null,
          days: null,
          status: true,
          priority: null,
        }
        await this.$refs.membershipNewPoliciesForm.reset()
      }
    },
    async deletePolicy(key){
      this.policies = this.policies.filter(policy => policy.key != key)
    },
    verifyDatePolicy(dateType, item, date){
      const datesAlreadySelected = []

      this.policies.forEach(policy => {
        if (policy.key != item.key) {
          const alreadyUsed = moment(date).isBetween(policy.dateInit, policy.dateEnd)
          const dateInitCrossAnotherDates = moment(policy.dateInit).isBetween(item.dateInit, item.dateEnd)
          const dateEndCrossAnotherDates = moment(policy.dateEnd).isBetween(item.dateInit, item.dateEnd)
          if (alreadyUsed || dateInitCrossAnotherDates || dateEndCrossAnotherDates) datesAlreadySelected.push(policy.name)
        }
      })

      const datesInRange = this.policies.filter(policy => {
        if (policy.key != item.key) {
          const isinRange = moment(policy[dateType]).isBetween(item.dateInit, item.dateEnd)
          if (isinRange) return policy
        }
      })


      if (moment(item.dateInit).isAfter(item.dateEnd)){
        item[dateType] = null
        showAlertMessage('No se ha actualizado la fecha', 'InfoIcon', `El rango de fechas seleccionado es inválido`, 'warning', 4000, 'bottom-right' )
      }
      if (datesAlreadySelected.length > 0) {
        item[dateType] = null
        return showAlertMessage('No se ha actualizado la fecha', 'InfoIcon', `La fecha seleccionada ya está relacionada con otra política: ${datesAlreadySelected}`, 'warning', 4000, 'bottom-right' )
      }
      if (datesInRange.length > 0) {
        const nameDatesInRange = datesInRange.map(dates => dates.name)
        item[dateType] = null
        return showAlertMessage('No se ha actualizado la fecha', 'InfoIcon', `La fecha seleccionada ya está relacionada con otra política: ${nameDatesInRange}`, 'warning', 4000, 'bottom-right' )
      }

      return true
    },
    checkPriority(){
      if (this.policies.length == 0) return showAlertMessage('No se han añadido las politicas de cancelación', 'InfoIcon', `Por favor, añada políticas de cancelación para continuar`, 'warning', 4000, 'bottom-right' )
      if (this.policies.some(policy => policy.priority == 0)) return showAlertMessage('No se han añadido las politicas de cancelación', 'InfoIcon', `Existe una politica sin prioridad: ${priorityTaken.name}`, 'warning', 4000, 'bottom-right' )
      this.policies.forEach(item => {
        const priorityTaken = this.policies.find(policy => (policy.priority == item.priority && (item.id ? item.id != policy.id : item.key != policy.key)))
        if (priorityTaken) {
          item.priority = null
          return showAlertMessage('No se ha añadido la prioridad', 'InfoIcon', `ya existe una politica con esta prioridad: ${priorityTaken.name}`, 'warning', 4000, 'bottom-right' )
        }
        if (item.priority > this.policies.length) {
          item.priority = null
          return showAlertMessage('No se ha añadido la prioridad', 'InfoIcon', `Limite de prioridad alcanzado`, 'warning', 4000, 'bottom-right' )
        }
      })
      return true
    },
    goToHome(){
      this.$router.push({ name: 'memberships-list' })
    },
  },
};
</script>
<style lang="scss">
.new-membership-card {
  padding: 0 1rem 1rem 1rem ;
}
.border-left.col-md-6 {
    border-left: 1px solid #dee2e6 ;
}
.mb-0.table-responsive {
    max-height: 369px;
}

.table-responsive th{
  padding:10px 10px 10px 10px!important;
  font-size:11px;

}
.table-responsive td{
  padding: 5px!important;
  font-size: 12px;
}

.custom-input-margin{
  margin: 0.34rem;
}
.flatpickr-color{
  background-color: white !important;
}
.flatpickr-color:disabled{
  background-color: #efefef !important;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
  .custom-table-overflow{
    max-height: 400px;
    overflow-x: hidden;
  }
  .custom-table-padding-tr td{
    padding: 0.70rem 1rem;
    align-self: center;
    word-break: break-word;
  }
  .custom-table-title-padding{
    padding: 0.70rem 1rem !important;
    align-self: center;
    word-break: break-word;
  }
  .wth-2{
    width: 2rem !important;
  }

  .wth-4{
    width: 4rem !important;
  }
</style>